import { Link, graphql } from 'gatsby'
import React from 'react'
import Layout from 'components/layout'
import Meta from 'components/meta'
import { Accordion, Card, Button } from 'react-bootstrap'
import ReactWOW from 'react-wow'
import get from 'lodash/get'
import parse from 'html-react-parser'
import Modal from 'react-modal'

class Services extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pageData: get(this.props.data, 'contentfulServices'),
      modalIsOpen: false,
      modalData: {},
    }
  }

  openModel = (event, item) => {
    event.preventDefault()
    this.setState({ modalIsOpen: true, modalData: item })
  }

  closeModal = e => {
    this.setState({ modalIsOpen: false, modalData: {} })
  }


  showCalendy = e => {
    Calendly.initPopupWidget({
      url:
        'https://calendly.com/limitlesswithjess/initial-90-min-consultation?text_color=4a4a4a&primary_color=ff0021',
    })
  }

  render() {
    const { location } = this.props
    const { pageData, modalData } = this.state
    const siteMetadata = {
      title: 'Services | Limitless With Jess',
      description: 'Gatsby starter for bootstrap a blog',
      siteUrl: 'https://limitlesswithjess.com/services',
    }
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '760px',
      },
    }
    return (
      <Layout location={location}>
        <Meta site={siteMetadata} />
        <div className="content-wrapper">
          <div className="main-wrapper clearfix">
            <div className="">
              <div id="about-page__bg-widget" className="widget-holder ">
                {/* <div
                  className="widget-bg"
                  style={{
                    background: `url(${pageData.bannerImage.file.url})`,
                    backgroundSize: 'cover'
                  }}
                >
                  <div className="widget-body container">
                   
                  </div>
                </div> */}
                <div className="service-bg">
                  <div className="container">
                    <div className="d-flex flex-column align-items-center flex-md-row justify-content-center service-image-wrap pt-5">
                      <div className="">
                        <div className="d-flex flex-column align-items-center justify-content-center  pt-4 pb-4">
                          <h1 className="mb-0">Services</h1>
                          <h4>360 Lifestyle Review</h4>
                          <div className="img-wrapper">
                            <img src="/assets/img/sb-4.png" />
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="about-page__info-widget" className="widget-holder ">
                <div className="container">
                  <div className="d-flex align-items-start row justify-content-between mt-5">
                    {pageData.services
                      ? pageData.services.map((item, index) => {
                          return (
                            <ReactWOW
                              animation={
                                index % 2 === 0 ? 'fadeInLeft' : 'fadeInRight'
                              }
                              key={index}
                              duration=".80s"
                            >
                              <div className="col-md-6 col-sm-12 mb-md-2">
                                <div className="services-block mr-md-4">
                                  <div className="d-flex flex-column align-items-start mb-3">
                                    <div className="image-data-service ">
                                      <div className="image-size-block">
                                      <img
                                        src={
                                          item.icon ? item.icon.file.url : ''
                                        }
                                        alt={
                                          item.icon && item.icon.description
                                            ? item.icon.description
                                            : 'no-alt'
                                        }
                                        height="auto"
                                      />
                                      </div>
                                    </div>
                                    <h4 className="mb-0">
                                      {item.heading ? item.heading : ''}
                                    </h4>
                                  </div>
                                  <div className="">
                                    {item.shortDescription
                                      ? item.shortDescription.shortDescription
                                      : ''}
                                  </div>
                                  <div className="mt-3">
                                    <button
                                      onClick={e => this.openModel(e, item)}
                                      className="explore"
                                    >
                                      Explore...
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </ReactWOW>
                          )
                        })
                      : ''}
                  </div>
                </div>
              </div>

              <div className="widget-holder btn-schdule">
                <div className="widget-bg">
                  <div className="widget-body container-fluid">
                    <div className="row ">
                      <div className="col">
                        <div href="#" className="">
                          <h2 className="text-white color-white">
                            {pageData.appointmentSectionHeading1
                              ? pageData.appointmentSectionHeading1
                              : ''}
                          </h2>
                          <button
                            onClick={e => this.showCalendy(e)}
                            className="btn btn-white bg-white"
                          >
                            {pageData.appointmentSectionButtonText
                              ? pageData.appointmentSectionButtonText
                              : ''}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {modalData ? (
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={e => this.closeModal(e)}
            contentLabel=""
            style={customStyles}
            ariaHideApp={false}
            closeTimeoutMS={0}
            shouldCloseOnOverlayClick={false}
          >
             <i className="feather-x close-btn c-p service-close float-right" onClick={e => this.closeModal(e)}></i>
            <div className="zoom-anim-dialog mt-4">
              <div className="services-block mt-md-4 mb-md-4">
                <div className="mdl-wrp d-flex flex-column flex-md-row align-items-center align-items-md-start">
                  <div className="ml-md-2 mb-sm-4">
                    <div className="image-size-block">
                    <img
                      src={modalData.icon2 ? modalData.icon2.file.url : ''}
                      alt={
                        modalData.icon2 && modalData.icon2.description
                          ? modalData.icon2.description
                          : 'no-alt'
                      }
                    />
                    </div>
                    
                  </div>
                 <div className="flex-column mr-md-4">
                 <h4 className="mr-md-4">
                    {modalData.heading ? modalData.heading : ''}
                  </h4>
                  <div className="maxHeightDialog pr-md-4">
                  {modalData.description
                    ? parse(modalData.description.childMarkdownRemark.html)
                    : ''}
                </div>
                <div className="modal-data-footer mt-4">
                 {modalData.buttonText && modalData.heading !== 'Genetic Analysis' ?
                <a href={modalData.link ? modalData.link : '/'} target="_blank" className="btn btn-fancy">
                  {modalData.buttonText}
                </a>
                : ''}
                {modalData.buttonText && modalData.heading == 'Genetic Analysis' ?
                <button onClick={e => this.showCalendy(e)} className="btn btn-fancy">
                  {modalData.buttonText}
                </button>
                : ''} 
            </div>
                 </div>
                </div>
                
                
              </div>
              
            </div>
            
          </Modal>
        ) : (
          ''
        )}
      </Layout>
    )
  }
}

export default Services

export const query = graphql`
  {
    contentfulServices {
      heading1
      bannerImage {
        file {
          url
        }
      }
      appointmentSectionHeading1
      appointmentSectionButtonText
      services {
        heading
        link
        shortDescription {
          shortDescription
        }
        buttonText
        description {
          childMarkdownRemark {
            html
          }
        }
        icon {
          file {
            url
          }
          description
        }
        icon2 {
          file {
            url
          }
          description
        }
      }
    }
  }
`
